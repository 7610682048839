import Vue from 'vue';
import store from '../store/store';

export default {
  $store: store,

  /**
   * Whether the application is running in the Cloud mode.
   */
  isInCloudMode() {
    return JSON.parse(process.env.VUE_APP_CLOUD);
  },

  async isCognitoEnabled(withMfa = false) {
    const cogntioConfig = await Vue.axios.get('/api/auth/cognito/config');

    const { data } = cogntioConfig;

    const enabled = data?.enabled ?? false;
    const mfaEnabled = data?.mfaEnabled ?? false;

    const cognitoResult = enabled && (withMfa ? mfaEnabled : true);

    return cognitoResult;
  },

  getCognitoClientId() {
    return process.env.VUE_APP_COGNITO_CLIENT_ID;
  },

  getCognitoUserPoolId() {
    return process.env.VUE_APP_COGNITO_USER_POOL_ID;
  },

  getCognitoRegion() {
    return process.env.VUE_APP_COGNITO_REGION;
  },
};

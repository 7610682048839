import { render, staticRenderFns } from "./MFA.vue?vue&type=template&id=4c0d16ff&scoped=true"
import script from "./MFA.vue?vue&type=script&lang=js"
export * from "./MFA.vue?vue&type=script&lang=js"
import style0 from "./MFA.vue?vue&type=style&index=0&id=4c0d16ff&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c0d16ff",
  null
  
)

export default component.exports
<template>
  <v-app-bar color="$vuetify.themes[theme].headerBackground"
             app
             clipped-left
             clipped-right
  >
    <v-app-bar-nav-icon color="secondary"
                        @click="navBarToggle"
    />
    <v-img src="@/assets/logo.png"
           max-height="50"
           max-width="274"
           class="mx-4"
           contain
    />
    <v-combobox v-model="selectedAccount"
                :items="accountWithFullName"
                :search-input.sync="searchText"
                solo-inverted
                item-text="fullname"
                :label="$t('header.search')"
                class="mt-8 mx-8 px-8"
                @keydown="searchAccountBy"
    >
      <template #item="{item}">
        <div class="account-item">
          <span>{{ item.text }}</span>
          <span class="account-item--id">
            {{ item.value }}
          </span>
        </div>
      </template>
    </v-combobox>
    <v-spacer />
    <v-menu auto
            offset-y
            open-on-click
    >
      <template #activator="{ on }">
        <v-btn
          v-on="on"
        >
          <v-avatar size="32px"
                    item
                    class="mr-1"
          >
            <img v-if="user && user.image"
                 :src="user.image"
                 class="userHeaderAvatar"
            >
            <img v-else
                 src="@/assets/user-logo.png"
                 alt="User"
            >
          </v-avatar>
          <span>{{ user.displayname != "" ? user.displayname : user.username }}</span>
        </v-btn>
      </template>

      <v-list>
        <v-list-item @click="goToUser">
          <v-list-item-title>{{ $t('header.account') }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-title>{{ $t('header.logOut') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog
      v-model="studioConfirmation.showDialog"
      persistent
      width="unset"
    >
      <v-card>
        <v-card-title class="test-h6 white--text primary">
          {{ $t('studioConfirmationDialog.title') }}
        </v-card-title>
        <v-card-text>
          <div class="mt-4">
            <div
              v-if="studioConfirmation.isImageStudio"
            >
              {{ $t('studioConfirmationDialog.imageStudioDescription') }}
            </div>
            <div
              v-if="studioConfirmation.isVideoStudio"
            >
              {{ $t('studioConfirmationDialog.videoStudioDescription') }}
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="secondary"
            @click="submitLogout"
          >
            {{ $t('header.logOut') }}
          </v-btn>
          <v-btn @click="closeConfirmationDialog">
            {{ $t('globalActions.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import _ from 'lodash';
import authService from '../js/services/authService';
import userService from '../js/services/userService';
import accountsService from '../js/services/accountsService';
import imageStudioService from '../js/services/imageStudioService';
import videoStudioService from '../js/services/videoStudioService';

import {
  USER_PAGE, LOGIN_PAGE, ACCOUNT_PAGE, ACCOUNTS_PAGE,
} from '../js/router/pages';

export default {
  name: 'HeaderToolBar',
  data() {
    return {
      debouncing: null,
      menuItems:  [
        { title: 'Account Settings', action: 'goToAccount' },
        { title: 'Logout', action: 'logout' },
      ],
      accountNames:       [],
      selectedAccount:    null,
      searchText:         '',
      studioConfirmation: {
        showDialog:    false,
        isImageStudio: false,
        isVideoStudio: false,
      },
    };
  },
  computed: {
    accountWithFullName() {
      return this.accountNames.map(item => {
        const account = { ...item, fullname: `[${item.value}] ${item.text}` };

        return account;
      });
    },
    /**
     * Returns logged user.
     *
     * @return {null|Object}
     */
    user() {
      return userService.getAuthUser();
    },

  },
  watch: {
    selectedAccount() {
      this.gotoAccountId(this.selectedAccount.value);
    },
  },
  beforeMount() {
    this.loadAccounts(this.searchText);
  },
  methods: {
    /**
     * Loads the accounts identified by search criteria.
     *
     * @param {string} search - The search criteria.
     */
    loadAccounts(search) {
      accountsService.loadAccountNames(search)
        .then(data => {
          this.accountNames = data;
        }).catch(err => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    },

    /**
     * Searches the accounts by input
     * @param {*} event - The input event.
     */
    searchAccountBy(event) {
      if (!_.isNil(this.debouncing)) {
        this.debouncing.cancel(); // cancel previous debouncing.
      }

      if (event.key === 'Enter') {
        this.navigateToAccountsWith(this.searchText);

        return;
      }

      this.debouncing = _.debounce(() => {
        this.loadAccounts(this.searchText);

        this.navigateToAccountsWith(this.searchText);
      }, 800);

      this.debouncing();
    },

    gotoAccountId(id) {
      this.$router.push({
        name:   ACCOUNT_PAGE,
        params: {
          accountId: id,
        },
      });
    },

    navigateToAccountsWith(criteria) {
      this.$router.push({
        name:  ACCOUNTS_PAGE,
        query: {
          search_term: criteria,
        },
      });
    },

    /**
     * Toggle the navigation bar.
     */
    navBarToggle() {
      this.$emit('toggle-navbar');
    },

    /**
     * Show logout confirmation dialog if there are images / videos in studio.
     */
    logout() {
      const imageStudioHasImages = imageStudioService.getImagesCount() > 0;
      const videoStudioHasVideos = videoStudioService.getVideosCount() > 0;

      if (imageStudioHasImages || videoStudioHasVideos) {
        this.studioConfirmation.isImageStudio = imageStudioHasImages;
        this.studioConfirmation.isVideoStudio = videoStudioHasVideos;
        this.studioConfirmation.showDialog = true;
      } else {
        this.submitLogout();
      }
    },

    /**
     * Logout user and redirect to the login page.
     */
    async submitLogout() {
      await authService.logout();

      this.$router.push({ name: LOGIN_PAGE });
    },

    closeConfirmationDialog() {
      this.studioConfirmation.showDialog = false;
    },

    /**
     * Go to user's account page.
     */
    goToUser() {
      this.$router.push({ name: USER_PAGE });
    },

    /**
     * Search account by text or selected account.
     */
    searchAccount(account) {
      if (account && account.value) {
        this.goToAccountPage(account.value);
        this.$nextTick(() => {
          this.searchText = '';
          this.selectedAccount = null;
        });
      }
    },

    /**
     * Redirect to selected account page.
     *
     * @param {number|Object} id - Account identifier.
     */
    goToAccountPage(id) {
      this.$router.push({
        name:   ACCOUNT_PAGE,
        params: {
          accountId: id,
        },
      }).catch(err => err);
    },
  },
};
</script>

<style scoped lang="scss">
.account-item {
  display: flex;
  justify-content: space-between;

  width: 100%;
}

.v-list {
  padding: 0px;
}

.v-menu__content {
  margin-left: -25px;
}
.userHeaderAvatar{
  object-fit: contain;
  width: 80px;
  height: 80px;
}
</style>

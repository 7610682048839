<template>
  <v-container
    fluid
    class="login"
  >
    <v-snackbar
      v-model="showMessage"
      timeout="8000"
      :color="colorMessage"
      top
      multi-line
    >
      {{ message }}
      <template #action>
        <v-btn
          icon
          @click="showMessage = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-card class="login__container mx-auto my-12">
      <v-img
        class="flex"
        max-width="539"
        src="@/assets/logo_LG.png"
      />

      <v-card-title class="login__container--title">
        {{ $t('pageTitles.mfaSetup') }}
      </v-card-title>

      <div class="login__container--qr-code">
        <QRCode :url="getTOTPUrl()" />
      </div>

      <div>
        <p>
          {{ $t('mfa.setup.scanQRCode') }}
        </p>
        <p>
          <span class="bold">{{ $t('mfa.setup.warn') }}</span>: {{ $t('mfa.setup.backupCode') }}
        </p>
      </div>

      <div class="pb-4">
        <v-btn
          class="mr-2"
          @click="copyCode"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 7H7V5H13V7Z"
              fill="currentColor"
            />
            <path
              d="M13 11H7V9H13V11Z"
              fill="currentColor"
            />
            <path
              d="M7 15H13V13H7V15Z"
              fill="currentColor"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3 19V1H17V5H21V23H7V19H3ZM15 17V3H5V17H15ZM17 7V19H9V21H19V7H17Z"
              fill="currentColor"
            />
          </svg>
        </v-btn>
        <v-btn @click="saveCode">
          {{ $t('mfa.setup.save') }}
        </v-btn>
      </div>

      <div>
        <p>{{ $t('mfa.enterCode') }}</p>
        <TOTPInput
          :id="id"
          :username="username"
          :session="session"
          :init="true"
          @verify-error="showErrorMessage"
        />
      </div>

      <app-version class="login__container--app-version" />
    </v-card>
  </v-container>
</template>

<script>
import AppVersion from '../components/AppVersion.vue';
import QRCode from '../components/QRCode.vue';
import TOTPInput from '../components/TOTPInput.vue';
import { LOGIN_PAGE } from '../js/router/pages';
import applicationService from '../js/services/applicationService';

export default {
  name:       'Login',
  components: { AppVersion, QRCode, TOTPInput },
  data() {
    return {
      message:      '',
      showMessage:  false,
      colorMessage: 'success',
      totpSecret:   this.$route.query.secret,
      id:           this.$route.query.id,
      username:     this.$route.query.username,
      session:      sessionStorage.getItem('mfaSession'),
      code:         '',
    };
  },
  async mounted() {
    if (!(await applicationService.isCognitoEnabled())) {
      this.$router.push({ name: LOGIN_PAGE });
    }
  },
  methods: {
    /**
     * Shows the error message notification.
     */
    showErrorMessage(msg) {
      this.showMessage = true;
      this.message = msg;
      this.colorMessage = 'error';
    },
    getTOTPUrl() {
      return `otpauth://totp/${encodeURIComponent(this.username ?? 'oVio mfa')}?secret=${this.totpSecret}&issuer=oVio`;
    },
    async saveCode() {
      const defaultFileName = 'ovio_backup_code.txt';

      const openSavePicker = async () => {
        const saveOptions = {
          startsin:      'Documents',
          suggestedName: defaultFileName,
          types:         [
            {
              accept: {
                'text/plain': ['.txt'],
              },
            },
          ],
        };

        const fileHandle = await window.showSaveFilePicker(saveOptions);

        // Write the contents of the file to the stream.
        const writable = await fileHandle.createWritable();

        await writable.write(this.totpSecret);
        await writable.close();

        // show success message
        this.colorMessage = 'success';
        this.message = this.$t('mfa.setup.saved');
        this.showMessage = true;
      };

      const basicFileDownload = () => {
        // Create dummy anchor element to download the file
        const element = document.createElement('a');
        const file = new Blob([this.totpSecret], { type: 'text/plain' });

        // set file name and trigger download
        element.href = URL.createObjectURL(file);
        element.download = defaultFileName;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);

        // show success message
        this.colorMessage = 'success';
        this.message = this.$t('mfa.setup.saved');
        this.showMessage = true;
      };

      // Check if the browser supports the File System Access API
      // eslint-disable-next-line
      if ('showSaveFilePicker' in self) {
        await openSavePicker();
      } else {
        basicFileDownload();
      }
    },
    async copyCode() {
      await navigator.clipboard.writeText(this.totpSecret);

      // show success message
      this.colorMessage = 'success';
      this.message = this.$t('mfa.setup.copied');
      this.showMessage = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../css/variables";

.login {
  display: flex;
  align-items: center;
  max-width: 26em;
  padding: 0;

  &__container {
    padding: 2em;

    &--title {
      justify-content: center;
      padding-bottom: 0%;
    }

    &--actions {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &--qr-code {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    &--app-version {
      display: flex;
      justify-content: center;
      margin-top: 1em;
    }
  }
}

.bold {
  font-weight: 500;
}

</style>

<template>
  <v-container
    class="pt-0 px-12"
  >
    <v-row>
      <!--<h1 class="subHeader">
        {{ $t('imageStudio.title') }}
      </h1>-->
      <v-breadcrumbs class="text-h2!important"
                     large
                     :items="breadcrumbs"
      >
        <template #item="{ item }">
          <v-breadcrumbs-item
            :to="item.to"
            :disabled="item.disabled"
          >
            <h1 v-if="item.disabled"
                class="subHeader"
            >
              {{ item.text }}
            </h1>
            <h1 v-else
                class="breadcrumbLink"
            >
              {{ item.text }}
            </h1>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-row>
    <v-row>
      <v-col>
        <ImageEditor ref="imageEditor"
                     :enable-export="true"
        />
      </v-col>
    </v-row>
    <div class="content-with-sidebar">
      <!-- Toggle Button that stays on the screen -->
      <v-btn
        class="sidebar-toggle"
        icon
        @click="toggleSidebar"
      >
        <v-icon>{{ sidebarVisible ? 'arrow_forward_ios' : 'arrow_back_ios' }}</v-icon>
      </v-btn>
      <v-navigation-drawer
        v-model="sidebarVisible"
        app
        clipped
        right
        width="275"
      >
        <h2 class="pl-2">
          {{ $t('imageStudio.sideBar.accounts') }}
        </h2>
        <v-list>
          <v-list-group
            v-for="account in accounts"
            :key="account.accountId"
            value="true"
          >
            <template #activator>
              <v-list-item-title @click="goToAccountPage(account.accountId)">
                {{ account.accountName }}
              </v-list-item-title>
            </template>
            <v-list-group
              v-for="capture in account.captures"
              :key="capture.captureId"
              no-action
              sub-group
              value="true"
            >
              <template #activator>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ 'Capture Id: ' + capture.captureId }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-menu top
                          close-on-click
                          offset-y
                          offset-x
                  >
                    <template #activator="{on}">
                      <v-btn icon
                             v-on="on"
                      >
                        <v-icon color="grey lighten-1">
                          more_vert
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list color="grey lighten-3">
                      <v-list-item>
                        <v-list-item-title @click="removeCaptureFromStudio(capture.captureId)">
                          {{ $t('imageStudio.sideBar.removeFromStudio') }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item-action>
              </template>

              <v-list-item
                v-for="frame in capture.frames"
                :key="frame.frame"
                class="pl-8"
              >
                <v-list-item-avatar min-height="50px">
                  <v-img
                    class="white--text align-end"
                    :src="getApiUrl(frame.image)"
                  />
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ 'Frame - ' + (frame.frame + 1) }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-menu top
                          close-on-click
                          offset-y
                          offset-x
                  >
                    <template #activator="{on}">
                      <v-btn icon
                             v-on="on"
                      >
                        <v-icon color="grey lighten-1">
                          more_vert
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list color="grey lighten-3">
                      <v-list-item>
                        <v-list-item-title @click="addToPage(capture.captureId, frame)">
                          {{ $t('imageStudio.sideBar.addToPage') }}
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title @click="removeFromStudio(capture.captureId, frame)">
                          {{ $t('imageStudio.sideBar.removeFromStudio') }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item-action>
              </v-list-item>
            </v-list-group>
          </v-list-group>
        </v-list>
      </v-navigation-drawer>
    </div>
  </v-container>
</template>

<script>
import ImageEditor from '../components/imageStudio/ImageEditor.vue';
import imageStudioService from '../js/services/imageStudioService';
import config from '../js/config';
import { ACCOUNT_PAGE, DERMAID_STUDIO_PAGE } from '../js/router/pages';

export default {
  name:       'ImageStudio',
  components: { ImageEditor },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.breadcrumbs = [vm.imageStudioBreadcrumb];
    });
  },
  data() {
    return {
      accounts:              [],
      prevRoute:             null,
      breadcrumbs:           [],
      imageStudioBreadcrumb: {
        text:     this.$t('imageStudio.title'),
        disabled: true,
        href:     null,
      },
      sidebarVisible: true,
    };
  },
  mounted() {
    this.loadStorageData();
  },
  methods: {
    goToAccountPage(id) {
      this.$router.push({
        name:   ACCOUNT_PAGE,
        params: {
          accountId: id,
        },
      });
    },

    toggleSidebar() {
    //  console.log('Sidebar toggle clicked');
      this.sidebarVisible = !this.sidebarVisible;
    },

    loadStorageData() {
      const images = imageStudioService.getImageStudioImages();

      this.accounts = [];

      images.forEach(img => {
        let foundAccount = this.accounts.find(a => a.accountId === img.accountId);

        if (!foundAccount) {
          foundAccount = { accountId: img.accountId, accountName: img.accountName, captures: [] };
          this.accounts.push(foundAccount);
        }

        let foundCapture = foundAccount.captures.find(act => act.captureId === img.captureId);

        if (!foundCapture) {
          foundCapture = {
            captureId: img.captureId,
            frames:    [],
          };
          foundAccount.captures.push(foundCapture);
        }

        let foundFrame = foundCapture.frames.find(f => f.frame === img.frame);

        if (!foundFrame) {
          foundFrame = {
            frame:    img.frame,
            angle:    img.angle,
            overhead: img.overhead,
            image:    img.image,
          };
          foundCapture.frames.push(foundFrame);
        }
      });
    },
    getApiUrl(image) {
      if (!image.startsWith('http')) {
        return `${config.apiUrl}${image}&width=200&height=200`;
      }

      return `${image}&width=200&height=200`;
    },
    addToPage(captureId, frame) {
      if (this.$router.currentRoute.name === DERMAID_STUDIO_PAGE) {
        this.$refs.imageEditor.addImageToDermaidPage(captureId, frame);
      } else {
        this.$refs.imageEditor.addImageToPage(captureId, frame.frame);
      }

      // console.log(`Add to page ${captureId}`);
    },
    removeFromStudio(captureId, frame) {
      // console.log(`Remove from studio ${captureId}`);

      const images = imageStudioService.getImageStudioImages();
      const removeImage = images
        .find(img => img.frame === frame.frame && captureId === img.captureId);

      // Remove it from the sidebar
      imageStudioService.removeFromImageStudio(removeImage);
      this.loadStorageData();

      // Have the page remove the canvas and commands
      this.$refs.imageEditor.removeImageFromPage(captureId, frame.frame);
    },
    removeCaptureFromStudio(captureId) {
      // console.log(`Remove from studio ${captureId}`);

      const images = imageStudioService.getImageStudioImages();
      const removeImages = images
        .filter(img => captureId === img.captureId);

      removeImages.forEach(removeImage => {
        imageStudioService.removeFromImageStudio(removeImage);
        this.loadStorageData();

        this.$refs.imageEditor.removeImageFromPage(captureId, removeImage.frame);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../css/variables.scss";

.subHeader {
  color: $grey-darken-1;
}

.title {
    background-color: $blue-title-2;
    padding: 16px !important;
    color: #FFFFFF;
  }

  .padNav {
    margin: 0 300px 0 0;
  }

  .sidebar-toggle {
  position: absolute;
  top: 10%;
  right:  0.625em;
  transform: translateY(-50%);
  z-index: 200;
}

@media screen and (max-width: 800px) {
  .sidebar-toggle {
    top: 10%;
    right:  0.625em;
    transform: translateY(-50%);
    z-index: 200;
  }
}

</style>

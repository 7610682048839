<template>
  <v-container
    class="pa-0"
  >
    <div class="content-with-sidebar">
      <!-- Toggle Button that stays on the screen -->
      <v-btn
        class="sidebar-toggle"
        icon
        @click="toggleSidebar"
      >
        <v-icon>{{ sidebarVisible ? 'arrow_forward_ios' : 'arrow_back_ios' }}</v-icon>
      </v-btn>
      <v-navigation-drawer
        v-model="sidebarVisible"
        app
        clipped
        right
        width="300"
      >
        <h2 class="pl-2">
          {{ $t('videoStudio.videoCompareSideBySide.sideBar.title') }}
        </h2>
        <v-list>
          <v-list-item-content class="pl-4">
            <v-list-item-title>
              {{ $t('videoStudio.videoCompareSideBySide.sideBar.activeVideos') }}
            </v-list-item-title>
          </v-list-item-content>
          <draggable
            :list="selectedVideos"
            group="videos"
            style="cursor: pointer;"
            @end="dragEnded"
          >
            <v-list-item
              v-for="item in selectedVideos"
              :key="item.captureId"
            >
              <v-list-item-avatar min-height="50px">
                <v-img
                  class="white--text align-end"
                  :src="item.preview"
                />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title @click="goToCapturePage(item.captureId)">
                  {{ item.account_name + ' - ' + item.captureId }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ getCaptureCreateTime(item.date_created) }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-menu top
                        close-on-click
                        offset-y
                        offset-x
                >
                  <template #activator="{on}">
                    <v-btn icon
                           v-on="on"
                    >
                      <v-icon color="grey lighten-1">
                        more_vert
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list color="grey lighten-3">
                    <v-list-item>
                      <v-list-item-title @click="removeFromPlaying(item.captureId)">
                        {{ $t('videoStudio.videoCompareSideBySide.sideBar.removePlaying') }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title @click="removeFromStudio(item.captureId)">
                        {{ $t('videoStudio.videoCompareSideBySide.sideBar.removeStudio') }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
          </draggable>
        </v-list>
        <v-list>
          <v-list-item-content class="pl-4">
            <v-list-item-title @click="goToAccountPage(item.accountId)">
              {{ $t('videoStudio.videoCompareSideBySide.sideBar.inactiveVideos') }}
            </v-list-item-title>
          </v-list-item-content>
          <draggable
            :list="unselectedVideos"
            group="videos"
            style="cursor: pointer;"
            @end="dragEnded"
          >
            <v-list-item
              v-for="item in unselectedVideos"
              :key="item.captureId"
            >
              <v-list-item-avatar min-height="50px">
                <v-img
                  class="white--text align-end"
                  :src="item.preview"
                />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title @click="goToCapturePage(item.captureId)">
                  {{ item.account_name + ' - ' + item.captureId }}
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-menu top
                        close-on-click
                        offset-y
                        offset-x
                >
                  <template #activator="{on}">
                    <v-btn icon
                           v-on="on"
                    >
                      <v-icon color="grey lighten-1">
                        more_vert
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list color="grey lighten-3">
                    <v-list-item>
                      <v-list-item-title @click="addToPlaying(item.captureId)">
                        {{ $t('videoStudio.videoCompareSideBySide.sideBar.AddPlaying') }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title @click="removeFromStudio(item.captureId)">
                        {{ $t('videoStudio.videoCompareSideBySide.sideBar.removeStudio') }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
          </draggable>
        </v-list>
      </v-navigation-drawer>
      <VideoSideBySidePlayer
        :videos="selectedVideos"
        :show-date="showDate"
        :show-timestamp="showTimestamp"
      />
    </div>
  </v-container>
</template>

<script>
import { saveAs } from 'file-saver/FileSaver';
import draggable from 'vuedraggable';
import videoStudioService from '../../js/services/videoStudioService';
import config from '../../js/config';
import VideoSideBySidePlayer from './VideoSideBySidePlayer.vue';
import applicationService from '../../js/services/applicationService';
import { CAPTURE_PAGE } from '../../js/router/pages';

export default {
  name:       'VideoCompareSideBySide',
  components: {
    draggable,
    VideoSideBySidePlayer,
  },
  props: {
    videos: {
      type:    Array,
      default: () => [],
    },
    showDate: {
      type:    Boolean,
      default: false,
    },
    showTimestamp: {
      type:    Boolean,
      default: false,
    },
    exportDirection: {
      type:    String,
      default: 'landscape',
    },
  },
  data() {
    return {
      selectedVideos:         [],
      unselectedVideos:       [],
      unselectedMenuExpanded: true,
      selectedMenuExpanded:   true,
      timer:                  null,
      localVideos:            [],
      sidebarVisible:         true,
    };
  },
  watch: {
    videos(newVal) {
      // console.log('compare page watched videos');
      this.localVideos = newVal;

      this.loadVideosFromStorage();

      if (this.selectedVideos.length === 0 && this.localVideos.length > 0) {
      // Could be first run or selected videos from storage are gone
      // We will try to get the first bunch
        this.selectedVideos = this.localVideos.slice(0, 6);
        this.unselectedVideos = this.localVideos.slice(6);

        videoStudioService.setSideBySideVideos(this.selectedVideos);
      }
      this.$emit('playing-updated', this.selectedVideos);
    },
  },
  mounted() {
    // console.log('side by side mounted');
    this.localVideos = this.videos;

    this.loadVideosFromStorage();
    if (this.selectedVideos.length === 0 && this.localVideos.length > 0) {
      // Could be first run or selected videos from storage are gone
      // We will try to get the first bunch
      this.selectedVideos = this.localVideos.slice(0, 6);
      this.unselectedVideos = this.localVideos.slice(6);

      videoStudioService.setSideBySideVideos(this.selectedVideos);
    }
  },
  methods: {

    goToCapturePage(id) {
      this.$router.push({
        name:   CAPTURE_PAGE,
        params: {
          captureId: id,
        },
      });
    },
    toggleSidebar() {
      // console.log('Sidebar toggle clicked');
      this.sidebarVisible = !this.sidebarVisible;
    },
    loadVideosFromStorage() {
      const selected = videoStudioService.getSideBySideSelectedVideos();

      if (!selected) {
        return;
      }

      this.selectedVideos = [];
      selected.forEach(video => {
        const found = this.localVideos.find(vid => vid.captureId === video.captureId);

        if (found) {
          this.selectedVideos.push(found);
        }
      });

      this.localVideos.forEach(video => {
        const found = this.selectedVideos.find(vid => vid.captureId === video.captureId);

        if (!found) {
          this.unselectedVideos.push(video);
        }
      });
    },

    dragEnded() {
      while (this.selectedVideos.length > 6) {
        this.unselectedVideos.push(this.selectedVideos.pop());
      }

      videoStudioService.setSideBySideVideos(this.selectedVideos);
      this.$emit('playing-updated', this.selectedVideos);
    },

    removeFromStudio(captureId) {
      // console.log(`removeFromStudio ${captureId}`);
      this.localVideos = this.localVideos.filter(vid => vid.captureId !== captureId);
      this.selectedVideos = this.selectedVideos.filter(vid => vid.captureId !== captureId);
      this.unselectedVideos = this.unselectedVideos.filter(vid => vid.captureId !== captureId);

      videoStudioService.setSideBySideVideos(this.selectedVideos);
      videoStudioService.removeVideo(captureId);

      this.$emit('videos-update', this.localVideos);
      this.$emit('playing-updated', this.selectedVideos);
    },

    removeFromPlaying(captureId) {
      // console.log(`removeFromPlaying ${captureId}`);

      const selVideo = this.selectedVideos.find(vid => vid.captureId === captureId);

      this.unselectedVideos.push(selVideo);
      this.selectedVideos = this.selectedVideos.filter(vid => vid.captureId !== captureId);

      videoStudioService.setSideBySideVideos(this.selectedVideos);
      this.$emit('playing-updated', this.selectedVideos);
    },

    addToPlaying(captureId) {
      // console.log(`addToPlaying ${captureId}`);

      const selVideo = this.unselectedVideos.find(vid => vid.captureId === captureId);

      this.selectedVideos.push(selVideo);
      this.unselectedVideos = this.unselectedVideos.filter(vid => vid.captureId !== captureId);

      while (this.selectedVideos.length > 6) {
        this.unselectedVideos.push(this.selectedVideos.pop());
      }

      videoStudioService.setSideBySideVideos(this.selectedVideos);
      this.$emit('playing-updated', this.selectedVideos);
    },

    getCaptureCreateTime(dateCreated) {
      return this.$moment(dateCreated).format(config.dateTimeFormat);
    },

    async save(filename, exportDirection) {
      this.fileName = filename;

      const exportId = await videoStudioService.compareVideos(
        this.selectedVideos, this.showDate, this.showTimestamp, exportDirection,
      );

      if (!applicationService.isInCloudMode()) {
        setTimeout(async () => {
          await this.checkStatus(exportId);
        }, 1000);
      }
    },

    async checkStatus(exportId) {
      const status = await videoStudioService.getVideoStatus(exportId);

      if (status === 0)       {
        // Still downloading
        setTimeout(async () => {
          await this.checkStatus(exportId);
        }, 1000);
      }

      // finished
      if (status === 1) {
        const file = await videoStudioService.downloadVideo(exportId);
        let { fileName } = this;

        if (!fileName) {
          fileName = `export_video_${exportId}.mp4`;
        }

        saveAs(new Blob([file],
          { type: 'video/mp4' }),
        `${fileName}`);

        this.$emit('save-completed');
      }

      if (status === 2) {
        this.$emit('save-error');
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../css/variables";

.selected {
  border: 4px solid $orange-text;
  outline: 4px solid $orange-text;
  outline-offset: -4px;
  max-width: 175px;
}
.sidebar-toggle {
  position: absolute;
  top: 10%;
  right:  0.625em;
  transform: translateY(-50%);
  z-index: 200;
}

@media screen and (max-width: 800px) {
  .sidebar-toggle {
    top: 10%;
    right:  0.625em;
    transform: translateY(-50%);
    z-index: 200;
  }
}
</style>

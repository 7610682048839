import Vue from 'vue';
import { mapMutations, mapGetters } from 'vuex';
import store from '../store/store';
import config from '../config';
import {
  ADD_VIDEO_TO_VIDEO_STUDIO,
  CLEAR_VIDEOS_MUTATION,
  REMOVE_VIDEO_FROM_IMAGE_STUDIO,
  SET_VIDEO_STUDIO_VIEW,
  SET_PLAYLIST1_VIDEO_STUDIO,
  SET_PLAYLIST2_VIDEO_STUDIO,
  SET_SIDEBYSIDE_VIDEO_STUDIO,
  CLEAR_PLAYLISTS_VIDEO_STUDIO,
  CLEAR_SIDEBYSIDE_VIDEO_STUDIO,
  SET_UNSELECTED_VIDEOS,

} from '../store/mutations';
import {
  VIDEO_STUDIO_VIEW_GETTER,
  VIDEO_STUDIO_VIDEOS_GETTER,
  VIDEO_STUDIO_SIDEBYSIDE_VIDEOS_GETTER,
  VIDEO_STUDIO_PLAYLISTS_VIDEOS_GETTER,
  VIDEO_STUDIO_UNSELECTED_VIDEOS_GETTER,
} from '../store/getters';
import applicationService from './applicationService';

export default {
  $store: store,

  /**
   * Mutations from Vuex Store
   */
  ...mapMutations([
    ADD_VIDEO_TO_VIDEO_STUDIO,
    CLEAR_VIDEOS_MUTATION,
    REMOVE_VIDEO_FROM_IMAGE_STUDIO,
    SET_VIDEO_STUDIO_VIEW,
    SET_PLAYLIST1_VIDEO_STUDIO,
    SET_PLAYLIST2_VIDEO_STUDIO,
    SET_SIDEBYSIDE_VIDEO_STUDIO,
    CLEAR_PLAYLISTS_VIDEO_STUDIO,
    CLEAR_SIDEBYSIDE_VIDEO_STUDIO,
    SET_UNSELECTED_VIDEOS,
  ]),

  /**
   * Getters from Vuex Storage
   */
  ...mapGetters([
    VIDEO_STUDIO_VIEW_GETTER,
    VIDEO_STUDIO_VIDEOS_GETTER,
    VIDEO_STUDIO_SIDEBYSIDE_VIDEOS_GETTER,
    VIDEO_STUDIO_PLAYLISTS_VIDEOS_GETTER,
    VIDEO_STUDIO_UNSELECTED_VIDEOS_GETTER,
  ]),

  /**
   * Views variants for video studio slider.
   */
  views: {
    comparison:         'comparison',
    playlistComparison: 'playlistComparison',
  },

  setView(view) {
    this[SET_VIDEO_STUDIO_VIEW](view);
  },

  getView() {
    return this[VIDEO_STUDIO_VIEW_GETTER]() || 0;
  },

  /**
   * Add capture video to image studio.
   *
   * @param {Object} video - Video data.
   */
  addVideo(video) {
    this[ADD_VIDEO_TO_VIDEO_STUDIO](video);
  },

  getUnselectedVideos() {
    return this[VIDEO_STUDIO_UNSELECTED_VIDEOS_GETTER]();
  },

  setUnselectedVideos(videos) {
    this[SET_UNSELECTED_VIDEOS](videos);
  },

  /**
   * Remove capture video from video studio.
   *
   * @param {Object} video - Video data.
   */
  removeVideo(captureId) {
    this[REMOVE_VIDEO_FROM_IMAGE_STUDIO](captureId);
  },

  /**
   * Remove all videos from video studio.
   */
  removeAllVideos() {
    this[CLEAR_VIDEOS_MUTATION]();
  },

  /**
   * Remove all playlists videos from image studio.
   */
  removePlaylistsVideos() {
    this[CLEAR_PLAYLISTS_VIDEO_STUDIO]();
  },

  /**
   * Remove all side by side selected videos from image studio.
   */
  removeSideBySideVideos() {
    this[CLEAR_SIDEBYSIDE_VIDEO_STUDIO]();
  },

  /**
   * Set playlists videos for video studio.
   */
  setPlaylistsVideos(playlist1, playlist2) {
    this[SET_PLAYLIST1_VIDEO_STUDIO](playlist1);
    this[SET_PLAYLIST2_VIDEO_STUDIO](playlist2);
  },

  /**
   * Set side by side selected videos for video studio.
   */
  setSideBySideVideos(selectedVideos) {
    this[SET_SIDEBYSIDE_VIDEO_STUDIO](selectedVideos);
  },

  /**
   * Returns videos from image studio.
   *
   * @return {Array}
   */
  getVideoStudioVideos() {
    return this[VIDEO_STUDIO_VIDEOS_GETTER]();
  },

  /**
   * Returns videos from image studio.
   *
   * @return {Array}
   */
  getVideosCount() {
    return this[VIDEO_STUDIO_VIDEOS_GETTER]().length;
  },

  /**
   * Returns videos from image studio.
   *
   * @return {Array}
   */
  getSideBySideSelectedVideos() {
    return this[VIDEO_STUDIO_SIDEBYSIDE_VIDEOS_GETTER]();
  },

  /**
   * Returns videos from studio.
   *
   * @return {Array}
   */
  getPlaylistsSelectedVideos() {
    return this[VIDEO_STUDIO_PLAYLISTS_VIDEOS_GETTER]();
  },

  /**
   * Compare videos.
   *
   * @param {Array} videos - Videos array to compre.
   * * @param {Array} export_type - 1 for comparison and 2 for playlistComparison.
   *
   * @return {number}
   */
  async compareVideos(videos, enableDate, enableTimestamp, exportDirection) {
    const params = {
      playlists:        [],
      export_type:      1,
      enable_date:      enableDate,
      enable_timestamp: enableTimestamp,
      export_direction: exportDirection === 'landscape' ? 0 : 1,
    };

    params.playlists.push([]);
    videos.forEach(video => {
      params.playlists[0].push({ capture_id: video.captureId });
    });

    const response = await Vue.axios.post('/api/video_studio', params);

    return response.data.id;
  },

  /**
   * Compare playlist videos.
   *
   * @param {Array} playlist1 - Videos playlist array to compare.
   * @param {Array} playlist2 - Videos playlist array to compare.
   *
   * @return {number}
   */
  async comparePlaylistVideos(playlist1, playlist2, enableDate, enableTimestamp) {
    const params = {
      playlists:        [],
      export_type:      2,
      enable_date:      enableDate,
      enable_timestamp: enableTimestamp,
    };

    params.playlists.push([]);
    playlist1.forEach(video => {
      params.playlists[0].push({ capture_id: video.captureId });
    });

    if (playlist2.length > 0) {
      params.playlists.push([]);
      playlist2.forEach(video => {
        params.playlists[1].push({ capture_id: video.captureId });
      });
    }

    const response = await Vue.axios.post('/api/video_studio', params);

    return response.data.id;
  },

  /**
   * Compare videos status.
   *
   * @param {Array} exportId - the export project id.
   *
   * @return {number} - 0 = processing, 1 = finished, 2 = error
   */
  async getVideoStatus(exportId) {
    const response = await Vue.axios.get(`/api/video_studio/${exportId}/status`);

    return response.data.status;
  },

  /**
   * Retrieve export video information
   *
   * @params {string} url - Video url.
   *
   * @return {Blob}
   */
  async getVideoUrl(id) {
    try {
      if (applicationService.isInCloudMode()) {
        const url = `/api/video_studio/${id}?download=false`;
        const response = await Vue.axios.get(url);

        // We get a Url for cloud scenario
        if (response.data.url) {
          return response.data.url;
        }
      }

      // Handle the local scenario here.
      return `${config.apiUrl}api/video_studio/${id}?download=false`;
    } catch (e) {
      return '';
    }
  },

  /**
   * Retrieve export video information
   *
   * @params {string} url - Video url.
   *
   * @return {Blob}
   */
  async downloadVideo(id) {
    const response = await Vue.axios.get(
      `/api/video_studio/${id}?download=true`, {
        responseType: 'blob',
      },
    );

    return response.data;
  },

  /**
   * Get info about video export.
   *
   * @param {number} id - Video compare identifier.
   */
  async getVideoInfo(id) {
    const response = await Vue.axios.get(
      `/api/video_studio/${id}/info`,
    );

    return response.data;
  },

  /**
   * Retrieve export video information
   *
   * @params {string} url - Video url.
   *
   * @return {Blob}
   */
  async getVideos(accountId) {
    const response = await Vue.axios.get(
      `/api/video_studio?accountId=${accountId}`,
    );

    return response.data;
  },

  /**
   * Delete Video Studio project.
   * @param {int} id - Video Studio project id.
   */
  async deleteProject(id) {
    const response = await Vue.axios
      .delete(`/api/video_studio/${id}`)
      .then(response => response.data)
      .catch(error => {
        throw error.response;
      });

    return response.data;
  },
};

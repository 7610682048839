<template>
  <canvas ref="canvas" />
</template>

<script>
import QRCode from 'qrcode';

export default {
  name:  'QRCode',
  props: {
    url: {
      type:     String,
      required: true,
    },
  },
  mounted() {
    QRCode.toCanvas(this.$refs.canvas, this.$props.url, error => {
      if (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    });
  },
};
</script>
